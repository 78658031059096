/*
 * 업무구분: 재가입대상계약 상세조회
 * 화 면 명: MSPPM851P
 * 화면설명: 재가입대상계약 상세조회
 * 접근권한: 모든사용자
 * 작 성 일: 2023.04.13
 * 작 성 자: 김진원
 */
<template>
  <ur-page-container :show-title="true" title="재가입대상계약 상세조회" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area pt30">
        <ur-box-container alignV="start" componentid="" direction="column" >
          <span class="label-title mb10">기간선택</span>
          <div class="ns-date-picker multi">
            <mo-date-picker v-model="dateValue1" placeholder="YYYY-MM-DD" /><span>~</span>
            <mo-date-picker v-model="dateValue2" placeholder="YYYY-MM-DD" />
          </div>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_initDate">초기화</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SetDate" :disabled="isDiabled">조회</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import moment from 'moment'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPPM851P",
  screenId: "MSPPM851P",
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    targetMonth1: {type: String},
    targetMonth2: {type: String},
  },
  watch: {
    dateValue1() {
      this.isDiabled = false
      let monthCnt = this.dateDiff(this.dateValue1, this.dateValue2)
      if ( this.dateValue1 !== '' && this.dateValue2 !== '' && new Date(this.dateValue1) > new Date(this.dateValue2)) {
        this.dateValue2 = new Date(moment(this.dateValue1).add(+3, 'months')).toString()
      } else if ( monthCnt > 93 ) {
        this.isDiabled = true
        this.getStore('confirm').dispatch('SETCOLOR', 'purple')
        this.getStore('confirm').dispatch('ADD', '조회 구간은 3개월 이내로만 가능합니다.')
      }
    },

    dateValue2() {
      this.isDiabled = false
      let monthCnt = this.dateDiff(this.dateValue1, this.dateValue2)
      if ( monthCnt > 93 ) { // 3개월 범위
        this.isDiabled = true
        this.getStore('confirm').dispatch('SETCOLOR', 'purple')
        this.getStore('confirm').dispatch('ADD', '조회 구간은 3개월 이내로만 가능합니다.')
      }
    }
  },
  mounted() {

  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      dateValue1: this.$props.targetMonth1,
      dateValue2: this.$props.targetMonth2,
      isDiabled: false,
    };
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_initDate(){
      let nowDate = moment(new Date()).format('YYYYMMDD')
      this.dateValue1 = this.$bizUtil.getFirstDayOfMonth(new Date(moment(nowDate)), 'String'),
      this.dateValue2 = this.$bizUtil.getLastDayOfMonth(new Date(moment(this.dateValue1).add(+2, 'months')), 'String')

    },

    fn_SetDate(){
      this._events.onPopupClose[0](this.dateValue1, this.dateValue2)
    },

    /******************************************************************************
    * Function명 : dateDiff
    * 설명       : 조회구간 날짜 차이
    *
    ******************************************************************************/
    dateDiff (_date1, _date2) {
      let diffDate_1 = new Date(_date1)
      let diffDate_2 = new Date(_date2)
      let diff = diffDate_2.getTime() - diffDate_1.getTime()
      diff = Math.ceil(diff / (1000 * 3600 * 24))
      return diff
    },

  }
};
</script>
<style scoped>
</style>